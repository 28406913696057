import * as React from "react"
import { useState } from "react"
import Seo from "../components/seo"

const DiscoveryServicesPage = () => {
  const [inquiryType, setInquiryType] = useState("")

  return (
    <div className="bg-white dark:bg-zinc-900">
      <Seo
        title="Discovery Services"
        description="Discover how to collaborate with Budmen Industries for innovative and exploratory projects."
        image="https://cdn.sanity.io/images/rbhjt84j/production/401f14b39a7a7b9712f4c8fc14373128cc4b3250-5920x6538.jpg"
        twitterImage="https://cdn.sanity.io/images/rbhjt84j/production/401f14b39a7a7b9712f4c8fc14373128cc4b3250-5920x6538.jpg"
      />

      <div
        className="bg-cover bg-center text-white flex items-center justify-center min-h-[75vh]"
        style={{
          backgroundImage:
            "url('https://cdn.sanity.io/images/rbhjt84j/production/1f413d22c6187e73f03c6d6935483ea5be21fc46-1920x1280.png')",
        }}
      >
        <div className="bg-black/[0.5] py-8 px-6 rounded text-center">
          <h3 className="subtitle futura text-sm pb-4 drop-shadow-[0_2px_2px_rgba(0,0,0,0.5)]">
            OUR SERVICES
          </h3>
          <h1 className="lg:text-5xl md:text-4xl text-3xl font-bold mb-4 alkacaps drop-shadow-[0_2px_2px_rgba(0,0,0,0.5)]">
            Let's Discover Together
          </h1>
          <p className="mt-4">
            Work with us to explore new ideas, answer complex questions, and
            spark innovation.
          </p>
        </div>
      </div>

      <section className="max-w-screen-lg mx-auto px-4 py-12">
        <div className="relative p-8 border-l-8 border-teal-600 rounded shadow-lg bg-zinc-200 dark:text-white dark:bg-zinc-800">
          <h6 className="alkacaps font-bold text-3xl mb-3">Inquiry Services</h6>
          <p className="mb-6">
            At Budmen Industries, we specialize in answering big questions and
            transforming visionary ideas into reality. Whether you're looking to
            solve a complex problem, develop a large-scale installation, or
            engage audiences through workshops and talks, we guide the process
            from start to finish. <em>Our services include:</em>
          </p>

          <div className="flex md:flex-row flex-col align-top">
            <div className="md:w-1/2 mr-4 mb-6 text-sm">
              <h6 className="alkacaps font-bold text-lg">Discovery Projects</h6>
              <img
                className="rounded shadow"
                src="https://cdn.sanity.io/images/rbhjt84j/production/171ee01b143af10eaf29b984cd42b7a1f235f25d-2096x1179.png?w=1600&h=800&fit=crop&crop=focalpoint&fp-x=0.5&fp-y=0.8"
                alt="Discovery Projects"
              />
              <p>
                We work with organizations and individuals to answer complex
                questions and turn bold ideas into reality.
                <br />
                <br />
                Whether the project involves detailed 3D scans, material
                innovation, or large-scale public installations, we guide every
                step: concept, design, fabrication, and installation. Our
                expertise spans from digital fabrication and archival work to
                cutting-edge explorations in science, art, and public spaces.
              </p>
            </div>
            <div className="md:w-1/2 mb-6 text-sm">
              <h6 className="alkacaps font-bold text-lg">
                Workshops & Speaking
              </h6>
              <img
                className="rounded shadow"
                src="https://cdn.sanity.io/images/rbhjt84j/production/4d2b64fbe70c3370238ef4d997089db8f8690272-1218x1000.png?w=1600&h=800&fit=crop&crop=focalpoint&fp-x=0.5&fp-y=0.48"
                alt="Workshops & Speaking"
              />
              <p>
                We offer workshops and talks that ignite creative discovery and
                spark innovation. These sessions dive into how curiosity,
                technology, and design come together to tackle real-world
                challenges.
                <br />
                <br />
                Our hands-on workshops and engaging talks challenge participants
                to rethink the ordinary, push boundaries, and explore new
                possibilities. From elementary school students to Fortune 500
                company corporate retreats, our experience spans all ages and
                industries, bringing fresh perspectives to every audience.
              </p>
            </div>
          </div>
        </div>

        {/* Inquiry Type Selection */}
        <div className="mt-12 p-8 bg-zinc-200 border-l-8 border-purple-600 dark:text-white dark:bg-zinc-800 rounded shadow-lg">
          <h3 className="text-2xl alkacaps font-bold mb-4 text-center">
            Ready to Start Your Inquiry?
          </h3>
          <p className="mb-6 text-center">
            Choose the type of inquiry you have, and we’ll ask a few key
            questions to help shape how we can collaborate on your next big
            idea.
          </p>

          <div className="flex justify-center space-x-4 mb-8">
            <button
              className={`py-2 px-4 rounded ${
                inquiryType === "project"
                  ? "bg-purple-600 text-white"
                  : "bg-purple-300 dark:bg-zinc-600"
              }`}
              onClick={() => setInquiryType("project")}
            >
              Discovery Project
            </button>
            <button
              className={`py-2 px-4 rounded ${
                inquiryType === "workshop"
                  ? "bg-purple-600 text-white"
                  : "bg-purple-300 dark:bg-zinc-600"
              }`}
              onClick={() => setInquiryType("workshop")}
            >
              Workshop/Speaking
            </button>
          </div>

          {/* Conditional Questions for Discovery Projects */}
          {inquiryType === "project" && (
            <form
              action="https://formsubmit.co/hello@budmen.com"
              method="POST"
              className="space-y-6"
            >
              {/* Name */}
              <div>
                <label className="block text-gray-900 dark:text-white">
                  Your Name
                </label>
                <input
                  type="text"
                  name="name"
                  className="mt-1 p-2 block w-full rounded border border-gray-300 dark:bg-zinc-700 dark:border-zinc-700 dark:text-white"
                  placeholder="Enter your name"
                  required
                />
              </div>

              {/* Email */}
              <div>
                <label className="block text-gray-900 dark:text-white">
                  Your Email
                </label>
                <input
                  type="email"
                  name="email"
                  className="mt-1 p-2 block w-full rounded border border-gray-300 dark:bg-zinc-700 dark:border-zinc-700 dark:text-white"
                  placeholder="Enter your email"
                  required
                />
              </div>

              {/* Organization */}
              <div>
                <label className="block text-gray-900 dark:text-white">
                  Organization
                </label>
                <input
                  type="text"
                  name="organization"
                  className="mt-1 p-2 block w-full rounded border border-gray-300 dark:bg-zinc-700 dark:border-zinc-700 dark:text-white"
                  placeholder="Enter your organization"
                />
              </div>

              {/* Phone Number */}
              <div>
                <label className="block text-gray-900 dark:text-white">
                  Phone Number
                </label>
                <input
                  type="tel"
                  name="phone"
                  className="mt-1 p-2 block w-full rounded border border-gray-300 dark:bg-zinc-700 dark:border-zinc-700 dark:text-white"
                  placeholder="Enter your phone number"
                />
              </div>

              {/* Existing fields */}
              <div>
                <label className="block text-gray-900 dark:text-white">
                  What is the project? What question or idea sparked it?
                </label>
                <textarea
                  name="problem"
                  rows="3"
                  className="mt-1 p-2 block w-full rounded border border-gray-300 dark:bg-zinc-700 dark:border-zinc-700 dark:text-white"
                  placeholder="We start with questions. What question or challenge led you to this idea?"
                  required
                ></textarea>
              </div>

              <div>
                <label className="block text-gray-900 dark:text-white">
                  What possibilities do you want to explore?
                </label>
                <textarea
                  name="newTerritory"
                  rows="3"
                  className="mt-1 p-2 block w-full rounded border border-gray-300 dark:bg-zinc-700 dark:border-zinc-700 dark:text-white"
                  placeholder="Are you hoping to uncover something unexpected, solve a unique challenge, or push the boundaries of what’s possible?"
                  required
                ></textarea>
              </div>

              <div>
                <label className="block text-gray-900 dark:text-white">
                  Where are you in your project journey?
                </label>
                <input
                  type="text"
                  name="processHelp"
                  className="mt-1 p-2 block w-full rounded border border-gray-300 dark:bg-zinc-700 dark:border-zinc-700 dark:text-white"
                  placeholder="Are you in the concept phase, design phase, or ready for fabrication?"
                  required
                />
              </div>

              <div>
                <label className="block text-gray-900 dark:text-white">
                  What makes this project exciting or significant to you?
                </label>
                <textarea
                  name="tangibleOutcome"
                  rows="3"
                  className="mt-1 p-2 block w-full rounded border border-gray-300 dark:bg-zinc-700 dark:border-zinc-700 dark:text-white"
                  placeholder="Tell us what excites you most about this project and why it matters to you or your organization."
                  required
                ></textarea>
              </div>

              <div>
                <label className="block text-gray-900 dark:text-white">
                  What is your project's timeframe?
                </label>
                <input
                  type="text"
                  name="timeframe"
                  className="mt-1 p-2 block w-full rounded border border-gray-300 dark:bg-zinc-700 dark:border-zinc-700 dark:text-white"
                  placeholder="ex: 3 months, 6 months, a millennium, etc..."
                  required
                />
              </div>

              {/* Hidden Fields for FormSubmit */}
              <input
                type="hidden"
                name="_next"
                value="https://budmen.com/contact-thanks"
              />
              <input
                type="hidden"
                name="_subject"
                value="New Discovery Project Inquiry"
              />
              <input type="text" name="_honey" style={{ display: "none" }} />

              <div className="flex justify-center mt-8">
                <button
                  type="submit"
                  className="items-center px-4 py-2 text-center text-white bg-purple-500 hover:bg-purple-600 rounded font-bold tracking-widest text-xs uppercase"
                >
                  SUBMIT INQUIRY
                </button>
              </div>
            </form>
          )}

          {/* Conditional Questions for Workshops/Speaking */}
          {inquiryType === "workshop" && (
            <form
              action="https://formsubmit.co/hello@budmen.com"
              method="POST"
              className="space-y-6"
            >
              {/* Name */}
              <div>
                <label className="block text-gray-900 dark:text-white">
                  Your Name
                </label>
                <input
                  type="text"
                  name="name"
                  className="mt-1 p-2 block w-full rounded border border-gray-300 dark:bg-zinc-700 dark:border-zinc-700 dark:text-white"
                  placeholder="Enter your name"
                  required
                />
              </div>

              {/* Email */}
              <div>
                <label className="block text-gray-900 dark:text-white">
                  Your Email
                </label>
                <input
                  type="email"
                  name="email"
                  className="mt-1 p-2 block w-full rounded border border-gray-300 dark:bg-zinc-700 dark:border-zinc-700 dark:text-white"
                  placeholder="Enter your email"
                  required
                />
              </div>

              {/* Organization */}
              <div>
                <label className="block text-gray-900 dark:text-white">
                  Organization
                </label>
                <input
                  type="text"
                  name="organization"
                  className="mt-1 p-2 block w-full rounded border border-gray-300 dark:bg-zinc-700 dark:border-zinc-700 dark:text-white"
                  placeholder="Enter your organization"
                />
              </div>

              {/* Phone Number */}
              <div>
                <label className="block text-gray-900 dark:text-white">
                  Phone Number
                </label>
                <input
                  type="tel"
                  name="phone"
                  className="mt-1 p-2 block w-full rounded border border-gray-300 dark:bg-zinc-700 dark:border-zinc-700 dark:text-white"
                  placeholder="Enter your phone number"
                />
              </div>

              {/* Existing fields */}
              <div>
                <label className="block text-gray-900 dark:text-white">
                  What are the goals for your workshop or talk?
                </label>
                <textarea
                  name="speakingGoals"
                  rows="3"
                  className="mt-1 p-2 block w-full rounded border border-gray-300 dark:bg-zinc-700 dark:border-zinc-700 dark:text-white"
                  placeholder="What do you hope to achieve through this workshop or speaking engagement?"
                  required
                ></textarea>
              </div>

              <div>
                <label className="block text-gray-900 dark:text-white">
                  Who is your target audience?
                </label>
                <textarea
                  name="audience"
                  rows="3"
                  className="mt-1 p-2 block w-full rounded border border-gray-300 dark:bg-zinc-700 dark:border-zinc-700 dark:text-white"
                  placeholder="Who will be attending? (e.g., students, professionals, educators)"
                  required
                ></textarea>
              </div>

              <div>
                <label className="block text-gray-900 dark:text-white">
                  Where will the event take place?
                </label>
                <input
                  type="text"
                  name="location"
                  className="mt-1 p-2 block w-full rounded border border-gray-300 dark:bg-zinc-700 dark:border-zinc-700 dark:text-white"
                  placeholder="Enter the location of the event"
                  required
                />
              </div>

              <div>
                <label className="block text-gray-900 dark:text-white">
                  What is the duration of the event?
                </label>
                <input
                  type="text"
                  name="duration"
                  className="mt-1 p-2 block w-full rounded border border-gray-300 dark:bg-zinc-700 dark:border-zinc-700 dark:text-white"
                  placeholder="Ex: 1 hour, full day, multi-day"
                  required
                />
              </div>

              <div>
                <label className="block text-gray-900 dark:text-white">
                  How many participants are you expecting?
                </label>
                <input
                  type="text"
                  name="participants"
                  className="mt-1 p-2 block w-full rounded border border-gray-300 dark:bg-zinc-700 dark:border-zinc-700 dark:text-white"
                  placeholder="Expected number of attendees"
                  required
                />
              </div>

              {/* Hidden Fields for FormSubmit */}
              <input
                type="hidden"
                name="_next"
                value="https://budmen.com/contact-thanks"
              />
              <input
                type="hidden"
                name="_subject"
                value="New Workshop/Speaking Inquiry"
              />
              <input type="text" name="_honey" style={{ display: "none" }} />

              <div className="flex justify-center mt-8">
                <button
                  type="submit"
                  className="items-center px-4 py-2 text-center text-white bg-purple-500 hover:bg-purple-600 rounded font-bold tracking-widest text-xs uppercase"
                >
                  SUBMIT INQUIRY
                </button>
              </div>
            </form>
          )}
        </div>
      </section>
    </div>
  )
}

export default DiscoveryServicesPage
